module services {
    export module applicationcore {
        export class eventTypeService implements interfaces.applicationcore.IEventTypeService {
            static $inject = ["$resource", "ENV", "$q"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,
                private $q: ng.IQService) {
            }
            
            getDropdownList(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return <ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel>>
                    this.$resource(this.ENV.DSP_URL + "EventType/GetDropdownList");
            }
            
        }
    }
    angular.module("app").service("eventTypeService", services.applicationcore.eventTypeService);
}